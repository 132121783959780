
import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import "./OrderSummary.css";

const OrderSummary = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [stats, setStats] = useState({ totalOrders: 0, paidOrders: 0 });
  const [sortOrder, setSortOrder] = useState(" ");
  const [filterOption, setFilterOption] = useState("pending");
  const restaurant_id = sessionStorage.getItem("restaurant_id");

  const handleMarkSelectedPaid = () => {
    selectedOrders.forEach((orderId) => handlePaymentChange(orderId, "Completed"));
    setSelectedOrders([]);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        if (!restaurant_id) {
          setOrders([]);
          return;
        }
        
        const response = await axios.get(
          `${config.API_BASE_URL}/customer/orders/${restaurant_id}`
        );
        setOrders(response.data);
        calculateStats(response.data);
      } catch (error) {
        console.log("Error details:", error.response);
        setOrders([]);
        calculateStats([]);
      }
    };

    fetchOrders();
  }, [restaurant_id]);

  const calculateStats = (orders) => {
    const totalOrders = orders.length;
    const paidOrders = orders.filter((order) => order.status === "Completed").length;
    setStats({ totalOrders, paidOrders });
  };

  const handleSortChange = (e) => {
    const newSortOrder = e.target.value;
    setSortOrder(newSortOrder);
    const sortedOrders = [...orders].sort((a, b) => {
      const dateA = new Date(a.order_date);
      const dateB = new Date(b.order_date);
      return newSortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    setOrders(sortedOrders);
  };

  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
  };

  const handlePaymentChange = async (orderId, status) => {
    try {
      await axios.post(
        `${config.API_BASE_URL}/kitchen/restaurant/${restaurant_id}/update-order-status/${orderId}`,
        { status: status }
      );
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.order_id === orderId ? { ...order, status: status } : order
        )
      );
      calculateStats(orders);
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const handleSelectOrder = (orderId) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  const filteredOrders = orders.filter((order) => {
    if (filterOption === "pending") {
      return order.status !== "Completed" && order.status !== "Cancelled";
    } else if (filterOption === "completed") {
      return order.status === "Completed";
    } else if (filterOption === "cancelled") {
      return order.status === "Cancelled";
    }
    return true;
  });

  const totalSelectedAmount = filteredOrders
    .filter((order) => selectedOrders.includes(order.order_id))
    .reduce((total, order) => total + order.total_amount, 0);

  return (
    <div className="order-dashboard">
      <h2>Order Summary</h2>
      <div className="sort-container">
        <label htmlFor="sort-order">Sort by: </label>
        <select id="sort-order" value={sortOrder} onChange={handleSortChange}>
          <option value="desc">Newest first</option>
          <option value="asc">Oldest first</option>
        </select>
      </div>
      <div className="filter-container">
        <label htmlFor="filter-option">Filter by: </label>
        <select
          id="filter-option"
          value={filterOption}
          onChange={handleFilterChange}
        >
          <option value="pending">Pending orders</option>
          <option value="completed">Completed orders</option>
          <option value="cancelled">Cancelled orders</option>
          <option value="all">All orders</option>
        </select>
      </div>
      <div className="stats-container">
        <div className="stat-card">
          <h3>Total Orders</h3>
          <p>{stats.totalOrders}</p>
        </div>
        <div className="stat-card">
          <h3>Paid Orders</h3>
          <p>{stats.paidOrders}</p>
        </div>
      </div>
      <div className="recent-orders">
        <h3>Recent Orders</h3>
        <table className="order-table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Table</th>
              <th>Order ID</th>
              <th>Customer Name</th>
              <th>Total</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => (
              <tr key={order.order_id}>
                <td>
                  {order.status !== "Completed" && order.status !== "Cancelled" ? (
                    <input
                      type="checkbox"
                      checked={selectedOrders.includes(order.order_id)}
                      onChange={() => handleSelectOrder(order.order_id)}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td>{order.table_number ? order.table_number : "Pick-up"}</td>
                <td>{order.order_id}</td>
                <td>{order.customer_name}</td>
                <td>${order.total_amount.toFixed(2)}</td>
                <td>{order.status}</td>
                <td>
                  {order.status === "Approved" ? (
                    <button onClick={() => handlePaymentChange(order.order_id, "Ready")}>
                      Mark Ready
                    </button>
                  ) : order.status === "Ready" ? (
                    <button onClick={() => handlePaymentChange(order.order_id, "Completed")}>
                      Mark Paid
                    </button>
                  ) : order.status === "Cancelled" ? (
                    "Cancelled"
                  ) : order.status !== "Completed" ? (
                    "Pending"
                  ) : (
                    "Paid"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {selectedOrders.length > 0 && (
          <div className="total-selected">
            <p>Total Selected Amount: ${totalSelectedAmount.toFixed(2)}</p>
            <button onClick={handleMarkSelectedPaid}>
              Mark Selected as Paid
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSummary;

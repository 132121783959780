import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import './UserProfile.css';

const countries = [
  { name: 'United States', states: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'] },
  { name: 'Canada', states: ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'] },
  { name: 'United Kingdom', states: ['England', 'Scotland', 'Wales', 'Northern Ireland'] },
  { name: 'Australia', states: ['New South Wales', 'Victoria', 'Queensland', 'Western Australia', 'South Australia', 'Tasmania', 'Australian Capital Territory', 'Northern Territory'] },
  { name: 'India', states: ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'] },
];

const UserProfile = () => {
  const user_id = parseInt(sessionStorage.getItem('user_id'));
  const [user, setUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editableUser, setEditableUser] = useState({});

  const handleEdit = () => {
    setEditMode(true);
    setEditableUser(user);
  };

  const handleSave = async () => {
    try {
      await axios.put(`${config.API_BASE_URL}/auth/user/${user_id}`, editableUser);
      setUser(editableUser);
      setEditMode(false);
    } catch (error) {
      console.error('Error updating user profile', error);
      alert('Error updating user profile');
    }
  };
  
  const handleChange = (e) => {
    setEditableUser({ ...editableUser, [e.target.name]: e.target.value });
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setEditableUser(prev => ({
      ...prev,
      country: selectedCountry,
      state: ''
    }));
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/auth/user/${user_id}`);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user profile', error);
        alert('Error fetching user profile');
      }
    };

    fetchUser();
  }, [user_id]);

  if (!user) return <div>Loading...</div>;

  return (
    <div className="user-profile">
      <h2>User Profile</h2>
      {editMode ? (
        <button onClick={handleSave}>Save</button>
      ) : (
        <button onClick={handleEdit}>Edit profile</button>
      )}
      <p>First Name: {editMode ? <input name="first_name" value={editableUser.first_name} onChange={handleChange} /> : user.first_name}</p>
      <p>Last Name: {editMode ? <input name="last_name" value={editableUser.last_name} onChange={handleChange} /> : user.last_name}</p>
      <p>Address: {editMode ? <input name="address" value={editableUser.address} onChange={handleChange} /> : user.address}</p>
      <p>City: {editMode ? <input name="city" value={editableUser.city} onChange={handleChange} /> : user.city}</p>
      <p>
        Country: 
        {editMode ? (
          <select name="country" value={editableUser.country} onChange={handleCountryChange}>
            <option value="">Select a country</option>
            {countries.map(country => (
              <option key={country.name} value={country.name}>{country.name}</option>
            ))}
          </select>
        ) : user.country}
      </p>
      <p>
        State: 
        {editMode ? (
          <select name="state" value={editableUser.state} onChange={handleChange}>
            <option value="">Select a state</option>
            {countries.find(c => c.name === editableUser.country)?.states.map(state => (
              <option key={state} value={state}>{state}</option>
            ))}
          </select>
        ) : user.state}
      </p>
      <p>Zip Code: {editMode ? <input name="zip_code" value={editableUser.zip_code} onChange={handleChange} /> : user.zip_code}</p>
      <p>Email: {user.email}</p>
      <p>Phone: {user.phone}</p>
    </div>
  );
};

export default UserProfile;

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import NotificationCart from './NotificationCart';
import config from '../config';
import './MenuManagement.css';

const MenuManagement = () => {
  const restaurant_id = sessionStorage.getItem('restaurant_id');
  const [menuItems, setMenuItems] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [isNotification, setIsNotification] = useState(false);
  const [formData, setFormData] = useState({
    item_name: '',
    description: '',
    price: '',
    category: '',
    image: '',
    available: true,
  });
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchMenuItems();
  }, []);

  
  const showNotification = (message, status) => {
    setNotificationMessage(message);
    setNotificationStatus(status);
    setIsNotification(true);
    setTimeout(() => {
      setIsNotification(false);
    }, 9000);
  };

  const fetchMenuItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.API_BASE_URL}/menu/get-menu-items/${restaurant_id}`);
      setMenuItems(response.data);
    } catch (error) {
      setError('Error fetching menu items');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : (type === 'file' ? files[0] : value),
    });
    if (name === 'image') setImagePreview(URL.createObjectURL(files[0]));
  };

  const handleImageUpload = async (imageFile) => {
    try {
      const fileName = encodeURIComponent(imageFile.name);
      const response = await axios.post(`${config.API_BASE_URL}/menu/generate-signed-url`, { file_name: fileName });
      const signedUrl = response.data.signed_url;
      await axios.put(signedUrl, imageFile, {
        headers: {
          'Content-Type': imageFile.type,
          'x-amz-acl': 'public-read',
         },
      });

      return signedUrl.split('?')[0]; // Return the URL for accessing the image
    } catch (error) {
      console.log("Error uploading image:", error);
      
      throw new Error('Image upload failed');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let imageUrl = formData.image;

      if (formData.image instanceof File) {
        imageUrl = await handleImageUpload(formData.image);
      }

      const formDataToSend = {
        ...formData,
        image: imageUrl,
        restaurant_id,
      };

      const endpoint = formData.menu_id
        ? `${config.API_BASE_URL}/menu/update-menu-item/${formData.menu_id}`
        : `${config.API_BASE_URL}/menu/add-menu-item`;

      await axios.post(endpoint, formDataToSend);

      fetchMenuItems();
      setFormData({
        item_name: '',
        description: '',
        price: '',
        category: '',
        image: '',
        available: true,
      });
      setImagePreview(null);
      if (fileInputRef.current) fileInputRef.current.value = '';
      
      showNotification(
        formData.menu_id ? 'Menu item updated successfully' : 'Menu item added successfully',
        'success'
      );
    } catch (error) {
      showNotification('Error adding/updating menu item', 'error');
    }
  };

  const handleDelete = async (menu_id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        await axios.delete(`${config.API_BASE_URL}/menu/delete-menu-item/${menu_id}`);
        setMenuItems(menuItems.filter(item => item.menu_id !== menu_id));
        showNotification('Menu item deleted successfully', 'success');
      } catch (error) {
        showNotification('Error deleting menu item', 'error');
      }
    }
  };

  const handleEdit = (item) => {
    setFormData({
      menu_id: item.menu_id,
      item_name: item.item_name,
      description: item.description,
      price: item.price,
      category: item.category,
      available: item.available,
      image: item.image,
    });
    setImagePreview(item.image);
  };

  return (
    <div className="menu-management">
      <h2>Menu Management</h2>
      {isNotification && (
        <NotificationCart
          message={notificationMessage}
          status={notificationStatus}
        />
      )}
      <form onSubmit={handleSubmit} className="menu-form">
        <div className="form-group">
          <label>Item Name:</label>
          <input type="text" name="item_name" value={formData.item_name} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Description:</label>
          <textarea name="description" value={formData.description} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Price:</label>
          <input type="number" name="price" value={formData.price} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Category:</label>
          <select name="category" value={formData.category} onChange={handleChange}>
            <option value="">Select a category</option>
            <option value="Starter">Starter</option>
            <option value="MainCourse">Main Course</option>
            <option value="Dessert">Dessert</option>
            <option value="Beverage">Beverage</option>
            <option value="TodaysSpecial">Today's Special</option>
          </select>
        </div>
        <div className="form-group">
          <label>Image:</label>
          <input type="file" name="image" onChange={handleChange} accept="image/*" ref={fileInputRef} />
        </div>
        {imagePreview && <img src={imagePreview} alt="Image Preview" className="image-preview" />}
        <div className="form-group">
          <label>Available:</label>
          <input type="checkbox" name="available" checked={formData.available} onChange={handleChange} />
        </div>
        <button type="submit">{formData.menu_id ? 'Update Menu Item' : 'Add Menu Item'}</button>
      </form>

      {loading ? (
        <p>Loading menu items...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="menu-items">
          {menuItems.map((item) => (
            <div key={item.menu_id} className="menu-card">
              <img src={item.image} alt={item.item_name} className="menu-card-image" />
              <div className="menu-card-content">
                <h3>{item.item_name}</h3>
                <p>{item.description}</p>
                <p className="menu-card-price">Price: ${item.price}</p>
                <div className="menu-card-actions">
                  <button className="edit-button" onClick={() => handleEdit(item)}>Edit</button>
                  <button onClick={() => handleDelete(item.menu_id)}>Delete</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuManagement;



import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../config";
import NotificationCart from "./NotificationCart";
import "./CustomerDetails.css";

const CustomerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cart = location.state?.cart || [];
  const restaurant_id = location.state?.restaurant_id;
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [formMode, setFormMode] = useState("login"); // login, signup, forgotPassword, guestLogin
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    customer_name: "",
    phone_number: "",
    restaurant_id: restaurant_id,
  });
  const [orderType, setOrderType] = useState("pickup");
  const [table_no, settable_number] = useState("");
  const [restaurant, setRestaurant] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [isNotification, setIsNotification] = useState(false);


  useEffect(() => {
    fetchRestaurantDetails();
    const storedUserData = localStorage.getItem("customerData");
    if (storedUserData) {
      try {
        const parsedData = JSON.parse(storedUserData);
        setUserData(parsedData);
        setIsLoggedIn(true);
      } catch (error) {
        console.error("Error parsing stored user data:", error);
        localStorage.removeItem("customerData");
      }
    }
  }, []);

  const showNotification = (message, status) => {
    setNotificationMessage(message);
    setNotificationStatus(status);
    setIsNotification(true);
    setTimeout(() => {
      setIsNotification(false);
    }, 9000);
  };

  const fetchRestaurantDetails = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/restaurant/get-restaurant/${restaurant_id}`
      );
      setRestaurant(response.data.data);
    } catch (error) {
      showNotification("Error fetching restaurant details", "error");
    }
  };

  const handleOrderTypeChange = (type) => {
    setOrderType(type);
    settable_number("");
  };

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/customer/login`,
        userData
      );
      setUserData(response.data);
      setIsLoggedIn(true);
      localStorage.setItem("customerData", JSON.stringify(response.data.data));
      setErrorMessage("");
    } catch (error) {
      console.error("Error logging in:", error);
      setErrorMessage("Invalid email or password");
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      setErrorMessage("Please accept the Terms and Conditions");
      return;
    }
    if (userData.password !== passwordConfirmation) {
      setErrorMessage("Passwords do not match");
      return;
    }
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/customer/create`,
        userData
      );
      setUserData(response.data.data);
      localStorage.setItem("customerData", JSON.stringify(response.data.data));
      setIsLoggedIn(true);
      setErrorMessage("");
    } catch (error) {
      console.error("Error signing up:", error);
      setErrorMessage(
        error.response?.data?.message ||
          "Error creating account. Please try again."
      );
    }
  };
  const handleGuestLogin = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      setErrorMessage("Please accept the Terms and Conditions");
      return;
    }
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/customer/create`,
        { ...userData, is_guest: true }
      );
      setUserData(response.data.data);
      localStorage.setItem("customerData", JSON.stringify(response.data.data));
      setIsLoggedIn(true);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          "Error creating account. Please try again."
      );
    }
  };
  
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/customer/forgot-password`,
        { email: resetEmail }
      );
      showNotification(response.data.message || "A password reset link has been sent to your email.", "success");
      setFormMode("login");
      setErrorMessage("");
    } catch (error) {
      showNotification(error.response?.data?.message || "Error resetting password. Please try again.", "error");
    }
  };

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!isLoggedIn && formMode !== "guestLogin") {
        showNotification("Please log in, create an account, or continue as guest to proceed.", "error");
        return;
      }

    const now = new Date();
    const currentTime = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;
  
    const orderItems = cart.map((item) => ({
      menu_item_id: item.menu_id,
      quantity: item.quantity,
    }));

    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/customer/place-order`,
        {
          restaurant_id: restaurant_id,
          customer_details: userData,
          order_items: orderItems,
          order_type: orderType,
          table_no: orderType === "dine-in" ? table_no : null,
          is_guest: formMode === "guestLogin",
          order_time: formattedTime
        }
      );
      if (response.status === 201) {
        localStorage.setItem("orderId", response.data.order_id);
        navigate("/customer/order-status", {
          state: { orderId: response.data.order_id, restaurant_id },
        });
      }
    } catch (error) {
      showNotification(
        error.response?.data?.message || "Error placing order",
        "error"
      );
    }
  };

  return (
    <div className="customer-details-container">
      <div className="customer-details-card">
        <h1>Customer Details</h1>
        {isNotification && (
          <NotificationCart
            message={notificationMessage}
            status={notificationStatus}
          />
        )}
        {isLoggedIn ? (
          <div>
            <h2>Welcome, {userData.customer_name || "Guest"}!</h2>
            <div className="order-type-selection">
              <h3>Select Your Order Type:</h3>
              <div className="toggle-switch">
                <button
                  type="button"
                  className={`switch-btn ${
                    orderType === "pickup" ? "active" : ""
                  }`}
                  onClick={() => handleOrderTypeChange("pickup")}
                >
                  Pickup
                </button>
                <button
                  type="button"
                  className={`switch-btn ${
                    orderType === "dine-in" ? "active" : ""
                  }`}
                  onClick={() => handleOrderTypeChange("dine-in")}
                >
                  Dine In
                </button>
              </div>
            </div>
            {orderType === "dine-in" && restaurant && (
              <div className="form-group">
                <label htmlFor="table-number">Table Number:</label>
                <select
                  id="table-number"
                  value={table_no}
                  onChange={(e) => settable_number(e.target.value)}
                  required
                >
                  <option value="">Select a table</option>
                  {[...Array(restaurant.number_of_tables)].map((_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <h2>Order Details</h2>
            <ul>
              {cart.map((item) => (
                <li key={item.menu_id}>
                  {item.item_name} - {item.quantity} x ${item.price.toFixed(2)}{" "}
                  = ${(item.price * item.quantity).toFixed(2)}
                </li>
              ))}
            </ul>
            <h3>Total: ${calculateTotal().toFixed(2)}</h3>
            <form onSubmit={handleSubmit}>
              <button type="submit">Submit Order</button>
            </form>
          </div>
        ) : (
          <>
            {formMode === "forgotPassword" && (
              <div>
                <h2>Forgot Password</h2>
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
                <form onSubmit={handleForgotPassword}>
                  <div className="form-group">
                    <label htmlFor="resetEmail">Email:</label>
                    <input
                      type="email"
                      value={resetEmail}
                      onChange={(e) => setResetEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <button type="submit">Send Reset Link</button>
                    <button type="button" onClick={() => setFormMode("login")}>
                      Back to Login
                    </button>
                  </div>
                </form>
              </div>
            )}

            {formMode === "login" && !isLoggedIn && (
              <div>
                <h2>Log In</h2>
                {errorMessage && (
                    <p className="error-message">{errorMessage}</p>
                  )}
                <form onSubmit={handleLogin}>
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      value={userData.email}
                      onChange={(e) =>
                        setUserData({ ...userData, email: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                      type="password"
                      value={userData.password}
                      onChange={(e) =>
                        setUserData({ ...userData, password: e.target.value })
                      }
                      required
                    />
                  </div>
                  <p>
                    <a href="#" onClick={() => setFormMode("forgotPassword")}>
                      Forgot Password?
                    </a>
                  </p>
                  <div className="form-group">
                    <button type="submit">Log In</button>
                    <button type="button" onClick={() => setFormMode("signup")}>
                      Create a new account
                    </button>
                    <button
                      type="button"
                      onClick={() => setFormMode("guestLogin")}
                    >
                      Login as Guest
                    </button>
                  </div>
                </form>
              </div>
            )}

              {formMode === "signup" && (
                <div>
                  <h2>Create an Account</h2>
                  {errorMessage && <p className="error-message">{errorMessage}</p>}
                  <form onSubmit={handleSignup}>
                    <div className="form-group">
                      <label htmlFor="name">Name:</label>
                      <input
                        type="text"
                        value={userData.customer_name}
                        onChange={(e) => setUserData({ ...userData, customer_name: e.target.value })}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email:</label>
                      <input
                        type="email"
                        value={userData.email}
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phoneNumber">Phone Number:</label>
                      <input
                        type="tel"
                        value={userData.phone_number}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                          setUserData({ ...userData, phone_number: value })
                        }}
                        pattern="[0-9]{10}"
                        maxLength="10"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password:</label>
                      <input
                        type="password"
                        value={userData.password}
                        onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="passwordConfirmation">Confirm Password:</label>
                      <input
                        type="password"
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                        required
                      />
                    </div>
                    <div className="terms-container">
                      <div className="form-group checkbox-group">
                        <input
                          type="checkbox"
                          id="terms-signup"
                          checked={termsAccepted}
                          onChange={(e) => setTermsAccepted(e.target.checked)}
                          required
                        />
                        <label htmlFor="terms-signup">
                          I agree to the{" "}
                          <a href="#" onClick={(e) => {e.preventDefault(); setShowTerms(!showTerms)}}>
                            Terms and Conditions
                          </a>
                        </label>
                      </div>
                      {showTerms && (
                        <div className="terms-card">
                          <h4>Terms and Conditions</h4>
                          <div className="terms-content">
                            <p>1. User Registration and Account</p>
                            <ul>
                              <li>Users must provide accurate and complete information</li>
                              <li>Users are responsible for maintaining account security</li>
                              <li>Users must be at least 18 years old to create an account</li>
                            </ul>
                            <p>2. Order and Payment</p>
                            <ul>
                              <li>All orders are subject to restaurant acceptance</li>
                              <li>Prices are subject to change without notice</li>
                              <li>Payment information must be valid and accurate</li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                    <button type="submit">Sign Up</button>
                    <button type="button" onClick={() => setFormMode("login")}>
                      Already have an account? Log In
                    </button>
                  </form>
                </div>
              )}

              {formMode === "guestLogin" && (
                <div>
                  <h2>Login as Guest</h2>
                  {errorMessage && <p className="error-message">{errorMessage}</p>}
                  <form onSubmit={handleGuestLogin}>
                    <div className="form-group">
                      <label htmlFor="guestEmail">Email:</label>
                      <input
                        type="email"
                        value={userData.email}
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="guestName">Name:</label>
                      <input
                        type="text"
                        value={userData.customer_name}
                        onChange={(e) => setUserData({ ...userData, customer_name: e.target.value })}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="guestPhone">Phone Number:</label>
                      <input
                        type="tel"
                        value={userData.phone_number}
                        onChange={(e) => setUserData({ ...userData, phone_number: e.target.value })}
                        required
                      />
                    </div>
                    <div className="terms-container">
                      <div className="form-group checkbox-group">
                        <input
                          type="checkbox"
                          id="terms-guest"
                          checked={termsAccepted}
                          onChange={(e) => setTermsAccepted(e.target.checked)}
                          required
                        />
                        <label htmlFor="terms-guest">
                          I agree to the{" "}
                          <a href="#" onClick={(e) => {e.preventDefault(); setShowTerms(!showTerms)}}>
                            Terms and Conditions
                          </a>
                        </label>
                      </div>
                      {showTerms && (
                        <div className="terms-card">
                          <h4>Terms and Conditions</h4>
                          <div className="terms-content">
                            <p>1. User Registration and Account</p>
                            <ul>
                              <li>Users must provide accurate and complete information</li>
                              <li>Users are responsible for maintaining account security</li>
                              <li>Users must be at least 18 years old to create an account</li>
                            </ul>
                            <p>2. Order and Payment</p>
                            <ul>
                              <li>All orders are subject to restaurant acceptance</li>
                              <li>Prices are subject to change without notice</li>
                              <li>Payment information must be valid and accurate</li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                    <button type="submit">Continue as Guest</button>
                  </form>
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerDetails;

import React, { useState } from 'react';
import bgImage from '../bg 4.png'; 
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Home.css';
import config from "../config";

const Home = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const getCurrentDay = () => {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    return days[new Date().getDay()];
  };


  const handleSearchChange = async (e) => {
    const query = e.target.value;
    if (query.length > 2) {
      const response = await axios.get(`${config.API_BASE_URL}/restaurant/search-restaurants?query=${query}`);
      setSearchResults(response.data);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <div className="home">
      <header>
        <div className="hamburger" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <nav>
          <ul className={isMenuOpen ? 'active' : ''}>
            <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
            <li><a href="#features" onClick={toggleMenu}>Features</a></li>
            <li><a href="#pricing" onClick={toggleMenu}>Pricing</a></li>
            <li><a href="#testimonials" onClick={toggleMenu}>Testimonials</a></li>
            <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
            <li><Link to="/login" onClick={toggleMenu}>Login</Link></li>
            <li><Link to="/register" onClick={toggleMenu}>Sign Up</Link></li>
          </ul>
        </nav>
      </header>
      <main>
      <section 
              id="hero" 
              className="hero" 
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
            >
          <h2>Welcome to Restaurant Table-Server-Assist</h2>
          <p>Manage your restaurant operations efficiently.</p>
          <Link to="/register" className="cta-button">Get Started</Link>
        </section>

        <section id="restaurant-search" className="restaurant-search">
          <h2>Find a Restaurant</h2>
          <input type="text" placeholder="Search for restaurants... By ('name' 'city' 'zipcode')" onChange={handleSearchChange} />
          <div className="restaurant-results">
          {searchResults.map(restaurant => (
          <div key={restaurant.id} className="restaurant-card">
            <h3>
              {restaurant.name}
              <Link to={`/menu-display?id=${restaurant.id}`}>
                <button className="order-now-btn">Order Now</button>
              </Link>
            </h3>
            <div className="restaurant-details">
              <p>{restaurant.description}</p>
              <p>📞 {restaurant.phone}</p>
              <p>📍 {restaurant.address}, {restaurant.city}</p>
              
              <details className="timing-dropdown">
                <summary>
                  Opening hours: {restaurant.opening_hours[getCurrentDay()].open} - {restaurant.opening_hours[getCurrentDay()].close}
                </summary>
                <div className="timing-content">
                  {Object.entries(restaurant.opening_hours).map(([day, hours]) => (
                    <p key={day}>
                      {day.charAt(0).toUpperCase() + day.slice(1)}: {hours.open} - {hours.close}
                      {hours.pickup && " (Pickup Available)"}
                    </p>
                  ))}
                </div>
              </details>
            </div>
          </div>
        ))}
          </div>
        </section>

        <section id="features" className="features">
          <h2>Features</h2>
          <div className="features-grid">
            <div className="feature-item hover-effect">
              <div className="feature-icon">🔄</div>
              <h3>Order Management</h3>
              <ul className="feature-list fade-in">
                <li>✨ Real-time order tracking</li>
                <li>📱 Instant notifications</li>
                <li>📊 Order history and analytics</li>
                <li>🔔 Custom order status updates</li>
              </ul>
            </div>

            <div className="feature-item hover-effect">
              <div className="feature-icon">📋</div>
              <h3>Menu Management</h3>
              <ul className="feature-list fade-in">
                <li>🎨 Digital menu creation</li>
                <li>⚡ Easy menu updates</li>
                <li>📑 Category organization</li>
                <li>🎉 Special offers management</li>
              </ul>
            </div>

            <div className="feature-item hover-effect">
              <div className="feature-icon">👥</div>
              <h3>Customer Experience</h3>
              <ul className="feature-list fade-in">
                <li>📱 QR code table ordering</li>
                <li>💳 Contactless payments</li>
                <li>⭐ Customer feedback system</li>
                <li>🎁 Loyalty program integration</li>
              </ul>
            </div>
          </div>
        </section>

        <section id="pricing" className="pricing">
        <h2>Choose Your Plan</h2>
        <div className="pricing-tiers">
          <div className="tier scale-effect">
            <div className="tier-header">
              <span className="tier-badge">Basic</span>
              <h3>Starter</h3>
              <div className="price">
                <span className="currency">$</span>
                <span className="amount">50</span>
                <span className="period">/month</span>
              </div>
            </div>
            <ul className="tier-features">
              <li>✅ Essential features</li>
              <li>✅ Real-time ordering</li>
              <li>✅ Basic analytics</li>
              <li>✅ Email support</li>
            </ul>
            <button className="tier-button">Get Started</button>
          </div>

          <div className="tier popular scale-effect">
            <div className="popular-badge">Most Popular</div>
            <div className="tier-header">
              <span className="tier-badge">Pro</span>
              <h3>Professional</h3>
              <div className="price">
                <span className="currency">$</span>
                <span className="amount">100</span>
                <span className="period">/month</span>
              </div>
            </div>
            <ul className="tier-features">
              <li>✅ All Basic features</li>
              <li>✅ Advanced analytics</li>
              <li>✅ Priority support</li>
              <li>✅ Custom branding</li>
              <li>✅ API access</li>
            </ul>
            <button className="tier-button highlight">Start Free Trial</button>
          </div>

          <div className="tier scale-effect">
            <div className="tier-header">
              <span className="tier-badge">Enterprise</span>
              <h3>Custom</h3>
              <div className="price">
                <span className="contact">Contact Us</span>
              </div>
            </div>
            <ul className="tier-features">
              <li>✅ All Pro features</li>
              <li>✅ Custom development</li>
              <li>✅ Dedicated support</li>
              <li>✅ SLA guarantee</li>
              <li>✅ Custom integrations</li>
            </ul>
            <button className="tier-button">Contact Sales</button>
          </div>
        </div>
      </section>

        <section id="testimonials" className="testimonials">
          <h2>Testimonials</h2>
          <p>Don't just take our word for it. Here's what our customers have to say:</p>
          <blockquote>
            <p>"Restaurant Table-Server-Assist has revolutionized our restaurant's operations. The real-time order tracking and digital menus have made us more efficient than ever!"</p>
            <footer>- Jane Doe, Restaurant Owner</footer>
          </blockquote>
          <blockquote>
            <p>"The QR code integration is a game-changer. Our customers love the convenience of scanning the code and placing their orders directly from their phones."</p>
            <footer>- John Smith, Restaurant Manager</footer>
          </blockquote>
        </section>
      </main>
    </div>
  );
};

export default Home;


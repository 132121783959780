import React, { useState } from "react";
import "./App.css";
import { Route, Routes, Link, useLocation, useNavigate } from "react-router-dom";
import Login from "./components/Login";
import Profile from "./components/Profile";
import Register from "./components/Register";
import Dashboard from "./components/Dashboard";
import CreateRestaurant from "./components/CreateRestaurant";
import UserProfile from "./components/UserProfile";
import PlaceOrder from "./components/PlaceOrder";
import OrderSummary from "./components/OrderSummary";
import GenerateQRCode from "./components/GenerateQRCode";
import Home from "./components/Home";
import MenuDisplay from "./components/MenuDisplay";
import SelectSubscription from "./components/SelectSubscription";
import OrderManagement from "./components/OrderManagement";
import KitchenManagement from "./components/KitchenManagement";
import CustomerOrderStatus from "./components/CustomerOrderStatus";
import CutomerDetails from "./components/CustomerDetails";
import VerifyEmail from "./components/VerifyEmail";
import Cart from "./components/Cart";
import AuthGuard from "./guard/AuthGuard";
import TrackOrder from "./components/TrackOrder"; // Import TrackOrder component. Newly added code
import ResetPassword from "./components/ResetPassword";
import Contact from "./components/Contact";
import About from "./components/About";
import TermsAndConditions from "./components/TermsAndConditions";
import CustomerLogin from "./components/CustomerLogin";
import CustomerOrders from "./components/CustomerOrders";

const App = () => {
  const [isLoginVisible, setLoginVisible] = useState(false);
  const [userData, setUserData] = useState(() => {
    const storedData = localStorage.getItem("customerData");
    return storedData && storedData !== "undefined" ? JSON.parse(storedData) : null;
  });
  const location = useLocation();
  const navigate = useNavigate();

  const handleLoginClick = () => {
    setLoginVisible(true);
  };

  const handleCloseLogin = () => {
    setLoginVisible(false);
  };
  const handleLoginSuccess = (data) => {
    setUserData(data); 
    setLoginVisible(false); 
  };
  const showLoginButtonPaths = ["/menu-display", "/cart", "/customer_details"];
  return (
    <div>
      <div className="header-container">
        <img src="/your-logo.png" alt="DineFaster Logo" className="logo" />
        <h1>Dinefaster</h1>
        {showLoginButtonPaths.includes(location.pathname) ? (
          userData ? (
            <div className="user-info-mD">
              <p>Welcome, {userData.customer_name}</p>
              <button
                onClick={() => {
                  navigate("/customer_orders", {state:{customerId:userData.customer_id }});
                }}
              >
                Order Status
              </button>
              <button
                onClick={() => {
                  localStorage.removeItem("customerData");
                  setUserData(null);
                }}
              >
                Logout
              </button>
            </div>
          ) : (
            <button onClick={handleLoginClick} className="login-button">
              Login
            </button>
          )
        ) : null}
      </div>
      {isLoginVisible && (
        <div className="login-modal">
          <CustomerLogin
            onLoginSuccess={handleLoginSuccess}
            onClose={handleCloseLogin}
          />
        </div>
      )}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/profile"
          element={<AuthGuard component={<Profile />} />}
        />
        <Route path="/dashboard/register" element={<Register />} />
        <Route
          path="/dashboard/*"
          element={<AuthGuard component={<Dashboard />} />}
        />
        <Route
          path="/dashboard/create-restaurant"
          element={<AuthGuard component={<CreateRestaurant />} />}
        />
        <Route
          path="/profile/:user_id"
          element={<AuthGuard component={<UserProfile />} />}
        />
        <Route path="/place-order" element={<PlaceOrder />} />
        <Route
          path="/order-summary/:restaurant_id/:order_id"
          element={<OrderSummary />}
        />
        <Route
          path="/generate-qr-code"
          element={<AuthGuard component={<GenerateQRCode />} />}
        />
        <Route path="/menu-display" element={<MenuDisplay />} />
        <Route
          path="/select-subscription"
          element={<AuthGuard component={<SelectSubscription />} />}
        />
        <Route path="/register" element={<Register />} />
        <Route path="/track-order" element={<TrackOrder />} />{" "}
        <Route
          path="/owner/orders"
          element={<AuthGuard component={<OrderManagement />} />}
        />
        <Route
          path="/kitchen/orders"
          element={<AuthGuard component={<KitchenManagement />} />}
        />
        <Route path="/customer_details" element={<CutomerDetails />} />
        <Route path="/customer_orders" element={<CustomerOrders />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route
          path="/customer/order-status"
          element={<CustomerOrderStatus />}
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
      <footer className="footer">
        <p>&copy; 2024 Restaurant Table-Server-Assist. All rights reserved.</p>
        <p className="footer-links">
          <Link to="/">Home</Link> |<Link to="/contact">Contact Us</Link> |
          <Link to="/about">About Us</Link> |
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
        </p>
      </footer>
    </div>
  );
};
export default App;

// src/config.js

const config = {
   // API_BASE_URL: 'https://rtsa.onrender.com/api/v1',
    // API_BASE_URL: 'http://localhost:5000/api/v1',
    // FRONTEND_URL: 'https://rtsaweb.onrender.com',
    // FRONTEND_URL:'http://localhost:3000',
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api/v1',
    FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000',
    
  };
  
  export default config;

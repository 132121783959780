
import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import './QRCodeScanning.css';
import config from "../config";

const QRCodeScanning = () => {
  const restaurant_id = sessionStorage.getItem('restaurant_id')
  const [restaurantId, setRestaurantId] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    handleGenerateQR()
  },[])

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Print QR Code</title>
          <style>
            .print-container {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
            }
            img {
              max-width: 300px;
              height: auto;
            }
          </style>
        </head>
        <body>
          <div class="print-container">
            <img src="${qrCodeUrl}" alt="QR Code" />
          </div>
          <script>
            window.onload = function() {
              window.print();
              window.onafterprint = function() {
                window.close();
              }
            }
          </script>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  const handleGenerateQR = useCallback(async () => {
    if (!restaurant_id) {
      setError('Error generating QR code');
      return;
    }

    setIsLoading(true);
    setError('');
    setQrCodeUrl('');

    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/menu/generate-qr-code`,
        { restaurant_id: restaurant_id },
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'image/png'
          }
        }
      );
      const contentType = response.headers['content-type'];
      
      if (contentType.includes('application/json')) {
        const errorResponse = await response.data.text();
        const jsonError = JSON.parse(errorResponse);

        if (jsonError.statusText === "NO_MENU_ITEMS") {
          setError('Please create a menu first.');
        } else {
          setError('Failed to generate QR code. Please try again.');
        }
      } else if (contentType.includes('image/png')) {
        const url = URL.createObjectURL(new Blob([response.data], { type: 'image/png' }));
        setQrCodeUrl(url);
      } else {
        setError('Failed to generate QR code. Please try again.');
      }
    } catch (error) {
      setError('Failed to generate QR code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [restaurant_id]);

  return (
    <div className="qr-code-scanning">
      <h2>Generate QR Code</h2>
      {error && <p className="error-message">{error}</p>}
      {isLoading && <p className="loading-message">Generating QR Code...</p>}
      {qrCodeUrl && (
        <div className="qr-code-container">
          <img src={qrCodeUrl} alt="QR Code" />
          <div className="button-container">
            <a href={qrCodeUrl} download={`qr_code_${restaurant_id}.png`} className="download-link">
              Download QR Code
            </a>
            <button onClick={handlePrint} className="print-button">
              Print QR Code
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QRCodeScanning;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import "./VerifyEmail.css";

const VerifyEmail = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const handleLogin = () => {
    window.location.href = '/login';
  };
  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/auth/verify-email/${email}`);
        if (response.data.status) {
          alert('Email verified successfully. You can now login.');
        //   handleLogin();
        } else {
          alert('Email verification failed. Please try again.');
        }
      } catch (error) {
        console.error('Error verifying email', error);
      }
    };
    verifyEmail();
    }, [email]);

  return (
    <div>
      <h2>Email verification</h2>
      <p>Your Email has been verified </p>
      <p>You can now login</p>
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default VerifyEmail;

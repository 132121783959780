import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Cart.css";
import axios from 'axios';
import config from "../config";

const Cart = () => {
    const location = useLocation();
    const [cartItems, setCartItems] = useState(location.state?.cart || []);
    const [restaurantDetail, setRestaurantDetail] = useState('');
    const restaurant_id = location.state?.restaurant_id;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMenu = async () => {
          try {
            const response = await axios.get(`${config.API_BASE_URL}/customer/display-menu/${restaurant_id}`);
            const allItems = response.data;
            setRestaurantDetail(allItems.resturant);
          } catch (error) {
            console.log("Error fetching menu:", error);
            
          }
        };
        fetchMenu();
      }, [restaurant_id]);
    const removeItem = (itemId) => {
        const updatedCart = cartItems.map(item => {
            if (item.menu_id === itemId) {
                if (item.quantity > 1) {
                    return { ...item, quantity: item.quantity - 1 };
                }
                return null;
            }
            return item;
        }).filter(Boolean);
        setCartItems(updatedCart);
    };

    const addItem = (itemId) => {
        const updatedCart = cartItems.map(item => {
            if (item.menu_id === itemId) {
                return { ...item, quantity: item.quantity + 1 };
            }
            return item;
        });
        setCartItems(updatedCart);
    };

    const calculateTotal = () => {
        return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    };

    const handleCheckout = () => {
        navigate('/customer_details', { state: { cart: cartItems, restaurant_id } });
    };

    return (
        <div className="cart-container">
            <div className="restaurant-info">
                <h1 className="restaurant-name">{restaurantDetail.name}</h1>
                <p className="restaurant-details">
                 {restaurantDetail.address}, {restaurantDetail.city}, {restaurantDetail.state} • {restaurantDetail.phone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                </p>
            </div>
            <h2>Your Cart</h2>
            <div className="cart-items">
                {cartItems.length > 0 ? cartItems.map((item) => (
                    <div key={item.menu_id} className="cart-item">
                    <img src={item.image} alt={item.item_name} />
                    <div className="item-details">
                        <h3>{item.item_name}</h3>
                        <p>Price: ${item.price}</p>
                        <p>Total: ${(item.price * item.quantity).toFixed(2)}</p>
                        <div className="quantity-controls">
                            <button onClick={() => removeItem(item.menu_id)}>-</button>
                            <span>{item.quantity}</span>
                            <button onClick={() => addItem(item.menu_id)}>+</button>
                        </div>
                    </div>
                </div>
                )) : <p>No items in cart</p>}
            </div>
            <div className="cart-total">
                <p>Subtotal: ${calculateTotal().toFixed(2)}</p>
            </div>
            <button className="checkout-button" onClick={handleCheckout}>Proceed to Checkout</button>
        </div>
    );
}

export default Cart;

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import NotificationCart from "./NotificationCart";
import './Register.css';

const countries = ['United States', 'Canada', 'United Kingdom', 'Australia', 'Germany', 'France', 'Japan', 'Brazil', 'India', 'China'];

const states = {
  'United States': ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'],
  'Canada': ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'],
  'United Kingdom': ['England', 'Scotland', 'Wales', 'Northern Ireland'],
  'Australia': ['New South Wales', 'Victoria', 'Queensland', 'Western Australia', 'South Australia', 'Tasmania', 'Australian Capital Territory', 'Northern Territory'],
  'Germany': ['Baden-Württemberg', 'Bavaria', 'Berlin', 'Brandenburg', 'Bremen', 'Hamburg', 'Hesse', 'Lower Saxony', 'Mecklenburg-Vorpommern', 'North Rhine-Westphalia', 'Rhineland-Palatinate', 'Saarland', 'Saxony', 'Saxony-Anhalt', 'Schleswig-Holstein', 'Thuringia'],
  'France': ['Île-de-France', 'Provence-Alpes-Côte d\'Azur', 'Nouvelle-Aquitaine', 'Occitanie', 'Hauts-de-France', 'Auvergne-Rhône-Alpes', 'Grand Est', 'Pays de la Loire', 'Normandy', 'Brittany', 'Bourgogne-Franche-Comté', 'Centre-Val de Loire', 'Corsica'],
  'Japan': ['Hokkaido', 'Tohoku', 'Kanto', 'Chubu', 'Kinki', 'Chugoku', 'Shikoku', 'Kyushu'],
  'Brazil': ['São Paulo', 'Rio de Janeiro', 'Minas Gerais', 'Bahia', 'Paraná', 'Rio Grande do Sul', 'Pernambuco', 'Ceará', 'Pará', 'Santa Catarina', 'Maranhão', 'Goiás', 'Amazonas', 'Espírito Santo', 'Paraíba', 'Rio Grande do Norte', 'Mato Grosso', 'Alagoas', 'Piauí', 'Distrito Federal', 'Mato Grosso do Sul', 'Sergipe', 'Rondônia', 'Tocantins', 'Acre', 'Amapá', 'Roraima'],
  'India': ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'],
  'China': ['Anhui', 'Fujian', 'Gansu', 'Guangdong', 'Guizhou', 'Hainan', 'Hebei', 'Heilongjiang', 'Henan', 'Hubei', 'Hunan', 'Jiangsu', 'Jiangxi', 'Jilin', 'Liaoning', 'Qinghai', 'Shaanxi', 'Shandong', 'Shanxi', 'Sichuan', 'Yunnan', 'Zhejiang', 'Guangxi', 'Inner Mongolia', 'Ningxia', 'Xinjiang', 'Tibet']
};

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    first_name: '',
    last_name: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
    selectedCountry: ''
  });

  const [errors, setErrors] = useState({});
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [isNotification, setIsNotification] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      const phone = value.replace(/\D/g, '').slice(0, 10);
      setFormData({ ...formData, [name]: phone });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    if (!termsAccepted) {
      setNotificationMessage('Please accept the Terms and Conditions');
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setNotificationMessage('Passwords do not match');
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
      return;
    }

    const { confirmPassword, selectedCountry, ...dataToSend } = formData;
    dataToSend.country = selectedCountry;
    
    try {
      const response = await axios.post(`${config.API_BASE_URL}/auth/register`, dataToSend);
      setNotificationMessage(response.data.message);
      setNotificationStatus("success");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
        navigate('/login');
      }, 9000);
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.data && error.response.data.message) {
        setNotificationMessage(error.response.data.message);
        setNotificationStatus("error");
      } else {
        setNotificationMessage('An error occurred during registration');
        setNotificationStatus("error");
      }
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
    }
  };

  return (
    <div className="register">
      <h2>Register</h2>
      {isNotification && (
        <NotificationCart
          message={notificationMessage}
          status={notificationStatus}
        />
      )}
      <form onSubmit={handleSubmit}>
        <label>Email:</label>
        <input type="email" name="email" value={formData.email} onChange={handleChange} required />

        <label>Password:</label>
        <input type="password" name="password" value={formData.password} onChange={handleChange} required />

        <label>Confirm Password:</label>
        <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />

        <label>First Name:</label>
        <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} required />

        <label>Last Name:</label>
        <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} required />

        <label>Phone:</label>
        <input 
          type="tel" 
          name="phone" 
          value={formData.phone} 
          onChange={handleChange}
          pattern="[0-9]{10}"
          maxLength="10"
          required 
        />
        <label>Address:</label>
        <input type="text" name="address" value={formData.address} onChange={handleChange} required />

        <label>City:</label>
        <input type="text" name="city" value={formData.city} onChange={handleChange} required />

        <label>Country:</label>
        <select name="selectedCountry" value={formData.selectedCountry} onChange={handleChange} required>
          <option value="">Select a Country</option>
          {countries.map(country => (
            <option key={country} value={country}>{country}</option>
          ))}
        </select>

        <label>State:</label>
        <select name="state" value={formData.state} onChange={handleChange} required>
          <option value="">Select a state</option>
          {formData.selectedCountry && states[formData.selectedCountry].map(state => (
            <option key={state} value={state}>{state}</option>
          ))}
        </select>

        <label>Zip Code:</label>
        <input type="text" name="zip_code" value={formData.zip_code} onChange={handleChange} required />

        <button type="submit">Register</button>
      </form>
      
      <div className="terms-container">
        <div className="form-group checkbox-group">
          <input
            type="checkbox"
            id="terms"
            checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  required
                />
                <label htmlFor="terms">
                  I agree to the Terms and Conditions
                  <a
                    href="#"
                    className="terms-toggle-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowTerms(!showTerms);
                    }}
                  >
                    {showTerms ? '▼' : '▶'}
                  </a>
                </label>
              </div>
              
              {showTerms && (
                <div className="terms-card">
                <p>1. User Registration and Account</p>
                <ul>
                  <li>Users must provide accurate and complete information</li>
                  <li>Users are responsible for maintaining account security</li>
                  <li>Users must be at least 18 years old to create an account</li>
                </ul>
                <p>2. Order and Payment</p>
                <ul>
                  <li>All orders are subject to restaurant acceptance</li>
                  <li>Prices are subject to change without notice</li>
                  <li>Payment information must be valid and accurate</li>
                </ul>
                <p>3. Privacy and Data Protection</p>
                <ul>
                  <li>User data will be handled as per our Privacy Policy</li>
                  <li>We maintain industry-standard security measures</li>
                </ul>
                </div>
              )}    
  
            </div>
    </div>
  );
};

export default Register;

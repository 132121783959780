
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CreateRestaurant.css';
import config from "../config";
import NotificationCart from './NotificationCart';

const countriesData = {
    USA: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'],
    Canada: ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'],
    UK: ['England', 'Scotland', 'Wales', 'Northern Ireland'],
    Australia: ['New South Wales', 'Victoria', 'Queensland', 'Western Australia', 'South Australia', 'Tasmania', 'Australian Capital Territory', 'Northern Territory'],
    Germany: ['Baden-Württemberg', 'Bavaria', 'Berlin', 'Brandenburg', 'Bremen', 'Hamburg', 'Hesse', 'Lower Saxony', 'Mecklenburg-Vorpommern', 'North Rhine-Westphalia', 'Rhineland-Palatinate', 'Saarland', 'Saxony', 'Saxony-Anhalt', 'Schleswig-Holstein', 'Thuringia'],
    France: ['Île-de-France', 'Provence-Alpes-Côte d\'Azur', 'Nouvelle-Aquitaine', 'Occitanie', 'Auvergne-Rhône-Alpes', 'Hauts-de-France', 'Grand Est', 'Pays de la Loire', 'Normandy', 'Brittany', 'Burgundy-Franche-Comté', 'Centre-Val de Loire', 'Corsica'],
    India: ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'],
    Japan: ['Hokkaido', 'Aomori', 'Iwate', 'Miyagi', 'Akita', 'Yamagata', 'Fukushima', 'Ibaraki', 'Tochigi', 'Gunma', 'Saitama', 'Chiba', 'Tokyo', 'Kanagawa', 'Niigata', 'Toyama', 'Ishikawa', 'Fukui', 'Yamanashi', 'Nagano', 'Gifu', 'Shizuoka', 'Aichi', 'Mie', 'Shiga', 'Kyoto', 'Osaka', 'Hyogo', 'Nara', 'Wakayama', 'Tottori', 'Shimane', 'Okayama', 'Hiroshima', 'Yamaguchi', 'Tokushima', 'Kagawa', 'Ehime', 'Kochi', 'Fukuoka', 'Saga', 'Nagasaki', 'Kumamoto', 'Oita', 'Miyazaki', 'Kagoshima', 'Okinawa']
};

const CreateRestaurant = () => {  
  const [formData, setFormData] = useState({
    restaurant_name: '',
    address: '',
    email: '',
    phone: '',
    restaurant_type: '',
    number_of_tables: 0,
    opening_hours: {
      monday: { open: '', close: '', pickup: false },
      tuesday: { open: '', close: '', pickup: false },
      wednesday: { open: '', close: '', pickup: false },
      thursday: { open: '', close: '', pickup: false },
      friday: { open: '', close: '', pickup: false },
      saturday: { open: '', close: '', pickup: false },
      sunday: { open: '', close: '', pickup: false },
    },
    city: '',
    country: '',
    state: '',
    zip_code: '',
    user_id: sessionStorage.getItem('user_id'),
  });

  const [stateOptions, setStateOptions] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [isNotification, setIsNotification] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (formData.country) {
      setStateOptions(countriesData[formData.country] || []);
      setFormData(prevData => ({ ...prevData, state: '' }));
    }
  }, [formData.country]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('opening_hours.')) {
      const [_, day, field] = name.split('.');
      if (field === 'open' || field === 'close') {
        const currentHours = formData.opening_hours[day];
        const openTime = field === 'open' ? value : currentHours.open;
        const closeTime = field === 'close' ? value : currentHours.close;
        
        if (openTime && closeTime && openTime >= closeTime) {
            setNotificationMessage('Closing time must be later than opening time');
            setNotificationStatus('error');
            setIsNotification(true);
            setTimeout(() => {
                setIsNotification(false);
            }, 9000);
            return;
        }
    }

    setFormData(prevData => ({
        ...prevData,
        opening_hours: {
            ...prevData.opening_hours,
            [day]: {
                ...prevData.opening_hours[day],
                [field]: type === 'checkbox' ? checked : value
            }
        }
    }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const dataToSubmit = {
      ...formData,
      opening_hours: formData.opening_hours,
    };

    try {
      const response = await axios.post(`${config.API_BASE_URL}/restaurant/create-restaurant`, dataToSubmit);
      if (response.status === 200) {
        setNotificationMessage(response.data.message);
        setNotificationStatus("success");
        setIsNotification(true);
        sessionStorage.setItem('restaurant_id', response.data.restaurant_id);
        setTimeout(() => {
          setIsNotification(false);
          navigate('/dashboard');
        }, 9000);
      }
    } catch (error) {
      console.error('Error creating restaurant:', error);
      setNotificationMessage("Error creating restaurant");
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
    }
  };

  return (
    <div className="create-restaurant">
      <h2>Create Restaurant</h2>
      {isNotification && (
        <NotificationCart
          message={notificationMessage}
          status={notificationStatus}
        />
      )}
      <form onSubmit={handleSubmit}>
        <label>Restaurant Name:</label>
        <input type="text" name="restaurant_name" value={formData.restaurant_name} onChange={handleChange} required />

        <label>Email:</label>
        <input type="email" name="email" value={formData.email} onChange={handleChange} required />

        <label>Phone:</label>
        <input 
          type="tel" 
          name="phone" 
          value={formData.phone} 
          onChange={handleChange}
          pattern="[0-9]{10}"
          maxLength="10"
          title="Please enter 10 digits"
          required 
        />

        <label>Address:</label>
        <input type="text" name="address" value={formData.address} onChange={handleChange} required />

        <label>City:</label>
        <input type="text" name="city" value={formData.city} onChange={handleChange} required />

        <label>State:</label>
        <select name="state" value={formData.state} onChange={handleChange} required>
          <option value="">Select a state</option>
          {stateOptions.map(state => (
            <option key={state} value={state}>{state}</option>
          ))}
        </select>

        <label>Zip Code:</label>
        <input type="text" name="zip_code" value={formData.zip_code} onChange={handleChange} required />

        <label>Country:</label>
        <select name="country" value={formData.country} onChange={handleChange} required>
          <option value="">Select a country</option>
          {Object.keys(countriesData).map(country => (
            <option key={country} value={country}>{country}</option>
          ))}
        </select>

        <label>Restaurant Type:</label>
        <input type="text" name="restaurant_type" value={formData.restaurant_type} onChange={handleChange} required />

        <label>Number of Tables:</label>
        <input type="number" name="number_of_tables" value={formData.number_of_tables} onChange={handleChange} required />

        <label>Opening Hours:</label>
        {Object.entries(formData.opening_hours).map(([day, hours]) => (
          <div key={day}>
            <label>{day.charAt(0).toUpperCase() + day.slice(1)}:</label>
            <input
              type="time"
              name={`opening_hours.${day}.open`}
              value={hours.open}
              onChange={handleChange}
            />
            <input
              type="time"
              name={`opening_hours.${day}.close`}
              value={hours.close}
              onChange={handleChange}
            />
            <label>
              <input
                type="checkbox"
                name={`opening_hours.${day}.pickup`}
                checked={hours.pickup}
                onChange={handleChange}
              />
              Pickup Available
            </label>
          </div>
        ))}

        <button type="submit">Create Restaurant</button>
      </form>
    </div>
  );
};

export default CreateRestaurant;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './MenuDisplay.css';
import axios from 'axios';
import config from "../config";

const MenuDisplay = () => {
  const query = new URLSearchParams(useLocation().search);
  const restaurant_id = query.get('id');
  localStorage.setItem('restaurant_id', restaurant_id);
  const [menu, setMenu] = useState([]);
  const [cart, setCart] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [restaurantDetail, setRestaurantDetail] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [categories, setCategories] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/customer/display-menu/${restaurant_id}`);
        const allItems = response.data;
        setMenu(allItems.menu);
        setRestaurantDetail(allItems.resturant);
        
        const uniqueCategories = [...new Set(allItems.menu
          .filter(item => item.category !== 'todaysspecial')
          .map(item => item.category))];
        setCategories(uniqueCategories);
      } catch (error) {
        setError('Failed to load menu. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchMenu();
  }, [restaurant_id]);

  const handleImageClick = (imageUrl) => {
    setPreviewImage(imageUrl);
  };

  const closePreview = () => {
    setPreviewImage(null);
  };

  const handleAddToCart = (item) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find(i => i.menu_id === item.menu_id);
      if (existingItem) {
        return prevCart.map(i =>
          i.menu_id === item.menu_id ? { ...i, quantity: i.quantity + 1 } : i
        );
      } else {
        return [...prevCart, { ...item, quantity: 1 }];
      }
    });
  };

  const handleUpdateCart = (menuId, quantity) => {
    if (quantity <= 0) {
      handleRemoveFromCart(menuId);
    } else {
      setCart((prevCart) =>
        prevCart.map(item =>
          item.menu_id === menuId ? { ...item, quantity } : item
        )
      );
    }
  };

  const handleRemoveFromCart = (menuId) => {
    setCart((prevCart) => prevCart.filter((item) => item.menu_id !== menuId));
  };

  const handleCheckout = async () => {
    if (cart.length === 0) {
      alert('Your cart is empty.');
      return;
    }
    navigate('/cart', { state: { cart, restaurant_id } });
  };

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  if (isLoading) {
    return <div>Loading menu...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  const specialItems = menu.filter(item => item.category === 'todaysspecial');

  return (
    <div className="menu-container">
      <div className="restaurant-info-m">
        <h1 className="restaurant-name">{restaurantDetail.name}</h1>
        <div className="restaurant-details">
          {restaurantDetail.address && <p>{restaurantDetail.address}</p>}
          {(restaurantDetail.address && (restaurantDetail.city || restaurantDetail.state)) && 
            <span className="separator">•</span>}
          {(restaurantDetail.city || restaurantDetail.state) && (
            <p>{`${restaurantDetail.city}${restaurantDetail.city && restaurantDetail.state ? ', ' : ''}${restaurantDetail.state}`}</p>
          )}
          {restaurantDetail.phone && (
            <>
              <span className="separator">•</span>
              <p>{restaurantDetail.phone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}</p>
            </>
          )}
        </div>
      </div>

      <div className="menu-display">
        {specialItems.length > 0 && (
          <div className="special-section">
            <h4 className="special-heading">Today's Special</h4>
            <div className="special-grid">
              {specialItems.map((item) => (
                <div key={item.menu_id} className="special-card">
                  <img 
                    src={item.image} 
                    alt={item.item_name} 
                    className="special-card-image" 
                    onClick={() => handleImageClick(item.image)}
                    style={{cursor: 'pointer'}}
                  />
                  <div className="special-card-content">
                    <h3>{item.item_name}</h3>
                    <p>{item.description}</p>
                    <p className="special-card-price">Price: ${item.price}</p>
                    <button onClick={() => handleAddToCart(item)}>Add to Cart</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        
        <hr className="menu-separator" />

        <div className="category-filter">
          <button 
            className={`category-btn ${selectedCategory === 'all' ? 'active' : ''}`}
            onClick={() => setSelectedCategory('all')}
          >
            All Items
          </button>
          {categories.map(category => (
            <button
              key={category}
              className={`category-btn ${selectedCategory === category ? 'active' : ''}`}
              onClick={() => setSelectedCategory(category)}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </button>
          ))}
        </div>
        
        <div className="menu-grid">
          {menu
            .filter(item => item.category !== 'todaysspecial')
            .filter(item => selectedCategory === 'all' || item.category === selectedCategory)
            .map((item) => (
              <div key={item.menu_id} className="menu-card">
                <img 
                  src={item.image} 
                  alt={item.item_name} 
                  className="menu-card-image"
                  onClick={() => handleImageClick(item.image)}
                  style={{cursor: 'pointer'}}
                />
                <div className="menu-card-content">
                  <h3>{item.item_name}</h3>
                  <p className="menu-description">{item.description}</p>
                  <p className="menu-card-price">Price: ${item.price}</p>

                  <div className="menu-card-actions">
                    <div className="menu-card-quantity">
                      <button onClick={() => handleUpdateCart(item.menu_id, (cart.find(i => i.menu_id === item.menu_id)?.quantity || 0) - 1)}>
                        -
                      </button>
                      <span>{cart.find(i => i.menu_id === item.menu_id)?.quantity || 0}</span>
                      <button onClick={() => handleAddToCart(item)}>+</button>
                    </div>
                    <button className="add-to-cart-button" onClick={() => handleAddToCart(item)}>Add to Cart</button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      
      {cart.length > 0 && (
        <div className="cart-section-floating">
          <div className="cart-total">
            <strong>Total: ${calculateTotal()}</strong>
          </div>
          <button className="checkout-button" onClick={handleCheckout}>
            Checkout
          </button>
        </div>
      )}

      {previewImage && (
        <div className="image-preview-overlay" onClick={closePreview}>
          <div className="image-preview-container">
            <img src={previewImage} alt="Preview" />
            <button className="close-preview" onClick={closePreview}>×</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuDisplay;

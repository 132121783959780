import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { useNavigate } from 'react-router-dom';

const SelectSubscription = ({ history }) => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/subscription/plans`);
        setPlans(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching subscription plans', error);
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const handleSelectPlan = async (planId) => {
    try {
      const userId = sessionStorage.getItem('user_id');
      const response = await axios.post(`${config.API_BASE_URL}/subscription/select-plan`, {
        user_id: userId,
        subscription_id: planId
      });
      alert(response.data.message);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error selecting subscription plan', error);
      alert('Error selecting subscription plan');
    }
  };

  if (loading) {
    return <div>Loading plans...</div>;
  }

  return (
    <div>
      <h2>Select a Subscription Plan</h2>
      <ul>
        {plans.map(plan => (
          <li key={plan.subscription_id}>
            <h3>{plan.subscription_name}</h3>
            <p>Price: ${plan.price}</p>
            <p>Duration: {plan.duration_months} months</p>
            <button onClick={() => handleSelectPlan(plan.subscription_id)}>Select</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectSubscription;

import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      <div className="terms-content">
        <section className="terms-section">
          <h2>1. Service Usage</h2>
          <p>By accessing and using DineFaster's services, you agree to comply with these terms and conditions...</p>
        </section>
        <section className="terms-section">
          <h2>2. User Responsibilities</h2>
          <p>Users are responsible for maintaining the confidentiality of their account credentials...</p>
        </section>
        <section className="terms-section">
          <h2>3. Privacy Policy</h2>
          <p>Our privacy policy outlines how we collect, use, and protect your personal information...</p>
        </section>
        <section className="terms-section">
          <h2>4. Payment Terms</h2>
          <p>All payments processed through our platform are secure and subject to our payment terms...</p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;

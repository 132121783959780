import React, { useState, useEffect } from "react";
import { Link, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import CreateRestaurant from "./CreateRestaurant";
import EditRestaurant from "./EditRestaurant";
import UserProfile from "./UserProfile";
import PlaceOrder from "./PlaceOrder";
import OrderSummary from "./OrderSummary";
import GenerateQRCode from "./GenerateQRCode";
import QRCodeScanning from "./QRCodeScanning";
import MenuDisplay from "./MenuDisplay";
import MenuManagement from "./MenuManagement";
import OrderManagement from './OrderManagement';
import KitchenManagement from './KitchenManagement';
import AuthGuard from "../guard/AuthGuard";
import axios from "axios";
import config from "../config";
import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hasRestaurant, setHasRestaurant] = useState(false);
  const [restaurant, setRestaurant] = useState({});
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [message, setMessage] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    fetchRestaurantStatus();
    if (location.pathname === '/dashboard') {
      setSelectedMenuItem('order-summary');
    }
    setSelectedMenuItem(location.pathname.split('/')[2] || 'order-summary');
  }, [location]);


  const fetchRestaurantStatus = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/restaurant/get-restaurant/${sessionStorage.getItem("restaurant_id")}`
      );
      sessionStorage.setItem("restaurant_id", response.data.data.restaurant_id);
      setRestaurant(response.data.data);
      setHasRestaurant(response.data.status);
    } catch (error) {
      console.error("Error fetching restaurant status:", error);
    }
  };

  const handleLogout = async () => {
    sessionStorage.clear();
    navigate("/");
  };

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
    setIsMenuOpen(false);
    window.scrollTo(0, 0);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="dashboard">
      <div className={`hamburger-menu ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <div className="hamburger-icon"></div>
      </div>

      <div className={`dashboard-menu ${isMenuOpen ? 'show' : ''}`}>
        <h2>Dashboard</h2>
        {message && <p className="alert-message">{message}</p>}
        <ul>
          <li>
            <Link
              to="order-summary"
              onClick={(e) => {
                if (hasRestaurant) {
                  handleMenuItemClick('order-summary');
                } else {
                  e.preventDefault();
                }
              }}
              className={selectedMenuItem === 'order-summary' ? 'active' : ''}
              style={{ pointerEvents: hasRestaurant ? 'auto' : 'none', color: hasRestaurant ? 'black' : 'gray' }}
            >
              Order Summary
            </Link>
          </li>
          {hasRestaurant ? (
            <li>
              <Link
                to="edit-restaurant"
                onClick={() => handleMenuItemClick('edit-restaurant')}
                className={selectedMenuItem === 'edit-restaurant' ? 'active' : ''}
              >
                Edit Restaurant
              </Link>
            </li>
          ) : (
            <li>
              <Link
                to="create-restaurant"
                onClick={() => handleMenuItemClick('create-restaurant')}
                className={selectedMenuItem === 'create-restaurant' ? 'active' : ''}
              >
                Create Restaurant
              </Link>
            </li>
          )}
          <li>
            <Link
              to="profile"
              onClick={(e) => hasRestaurant ? handleMenuItemClick('profile') : e.preventDefault()}
              className={selectedMenuItem === 'profile' ? 'active' : ''}
              style={{ pointerEvents: hasRestaurant ? 'auto' : 'none', color: hasRestaurant ? 'black' : 'gray' }}
            >
              Profile
            </Link>
          </li>
          <li>
            <Link
              to="generate-qr-code"
              onClick={(e) => hasRestaurant ? handleMenuItemClick('generate-qr-code') : e.preventDefault()}
              className={selectedMenuItem === 'generate-qr-code' ? 'active' : ''}
              style={{ pointerEvents: hasRestaurant ? 'auto' : 'none', color: hasRestaurant ? 'black' : 'gray' }}
            >
              Generate QR Code
            </Link>
          </li>
          <li>
            <Link
              to="menu-management"
              onClick={(e) => hasRestaurant ? handleMenuItemClick('menu-management') : e.preventDefault()}
              className={selectedMenuItem === 'menu-management' ? 'active' : ''}
              style={{ pointerEvents: hasRestaurant ? 'auto' : 'none', color: hasRestaurant ? 'black' : 'gray' }}
            >
              Menu Management
            </Link>
          </li>
          <li>
            <Link
              to="order-management"
              onClick={(e) => hasRestaurant ? handleMenuItemClick('order-management') : e.preventDefault()}
              className={selectedMenuItem === 'order-management' ? 'active' : ''}
              style={{ pointerEvents: hasRestaurant ? 'auto' : 'none', color: hasRestaurant ? 'black' : 'gray' }}
            >
              Order Management
            </Link>
          </li>
          <li>
            <Link
              to="kitchen-management"
              onClick={(e) => hasRestaurant ? handleMenuItemClick('kitchen-management') : e.preventDefault()}
              className={selectedMenuItem === 'kitchen-management' ? 'active' : ''}
              style={{ pointerEvents: hasRestaurant ? 'auto' : 'none', color: hasRestaurant ? 'black' : 'gray' }}
            >
              Kitchen Management
            </Link>
          </li>
        </ul>
        <button className="logout" onClick={handleLogout}>Log Out</button>
      </div>
      <div className="dashboard-content">
        <Routes>
        <Route path="/" element={<AuthGuard component={<OrderSummary />} />} />
          {hasRestaurant ? (
            <Route
              path="edit-restaurant"
              element={<AuthGuard component={<EditRestaurant />} />}
            />
          ) : (
            <Route
              path="create-restaurant"
              element={<AuthGuard component={<CreateRestaurant />} />}
            />
          )}
          <Route
            path="profile"
            element={<AuthGuard component={<UserProfile />} />}
          />
          <Route
            path="place-order"
            element={<AuthGuard component={<PlaceOrder />} />}
          />
          <Route
            path="order-summary"
            element={<AuthGuard component={<OrderSummary />} />}
          />
          <Route
            path="generate-qr-code"
            element={<AuthGuard component={<QRCodeScanning />} />}
          />
          <Route
            path="menus"
            element={<AuthGuard component={<MenuDisplay />} />}
          />
          <Route
            path="menu-management"
            element={<AuthGuard component={<MenuManagement />} />}
          />
          <Route
            path="order-management"
            element={<AuthGuard component={<OrderManagement />} />}
          />
          <Route
            path="kitchen-management"
            element={<AuthGuard component={<KitchenManagement />} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;

import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>About DineFaster</h1>
      <div className="about-content">
        <div className="mission-section">
          <h2>Our Mission</h2>
          <p>To revolutionize the dining experience by providing innovative digital solutions that enhance restaurant operations and customer satisfaction.</p>
        </div>
        <div className="features-grid">
          <div className="feature-card">
            <h3>Smart Ordering</h3>
            <p>Streamlined digital ordering system for faster service</p>
          </div>
          <div className="feature-card">
            <h3>Table Management</h3>
            <p>Efficient table allocation and management system</p>
          </div>
          <div className="feature-card">
            <h3>Real-time Updates</h3>
            <p>Instant order tracking and status updates</p>
          </div>
          <div className="feature-card">
            <h3>Analytics</h3>
            <p>Comprehensive reporting and business insights</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

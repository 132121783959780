import React, { useState, useEffect } from 'react'; 
import axios from 'axios';
import config from '../config';
import './OrderManagement.css';

function OrderManagement() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [addMenuItemOrder, setAddMenuItemOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const restaurantId = sessionStorage.getItem('restaurant_id');
  const [confirmDeleteOrderId, setConfirmDeleteOrderId] = useState(null);
  const [modifiedOrders, setModifiedOrders] = useState({}); // Track modified orders

  useEffect(() => {
    fetchOrders();
  }, [restaurantId]);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${config.API_BASE_URL}/owner/restaurant/${restaurantId}/orders`);
      setOrders(response.data);
    } catch (error) {
      setError('Failed to load orders. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleApproveOrder = (orderId) => {
    if (modifiedOrders[orderId]) {
      handleUpdateOrder(orderId);
    } else {
      approveOrder(orderId); 
    }
  };

  const approveOrder = async (orderId) => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}/owner/restaurant/${restaurantId}/approve-order/${orderId}`);
      if (response.status === 200) {
        fetchOrders();
      }
    } catch (error) {
      console.error('Error approving order:', error);
    }
  };

  const handleUpdateOrder = async (orderId) => {
    try {
      const modifiedOrder = modifiedOrders[orderId];
      const response = await axios.put(`${config.API_BASE_URL}/customer/update-order/${orderId}`, {
        order_items: modifiedOrder.items,
        total_amount: calculateOrderTotal(modifiedOrder.items)
      });

      if (response.status === 200) {
        fetchOrders();
        setModifiedOrders((prev) => {
          const updated = { ...prev };
          delete updated[orderId];
          return updated;
        });
      }
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  const calculateOrderTotal = (items) => {
    return items.reduce((total, item) => total + (item.price * item.quantity), 0);
  };

  const updateOrderItemQuantity = (orderId, itemId, newQuantity) => {
    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map(order => {
        if (order.order_id === orderId) {
          const updatedItems = order.items.map(item => 
            item.menu_item_id === itemId ? { ...item, quantity: newQuantity } : item
          );
          
          const newTotal = calculateOrderTotal(updatedItems);
          const modifiedOrder = { ...order, items: updatedItems, total_amount: newTotal };

          setModifiedOrders((prev) => ({
            ...prev,
            [orderId]: modifiedOrder
          }));

          return modifiedOrder;
        }
        return order;
      });
      return updatedOrders;
    });
  };

  const handleIncrementQuantity = (orderId, item) => {
    const newQuantity = item.quantity + 1;
    updateOrderItemQuantity(orderId, item.menu_item_id, newQuantity);
  };

  const handleDecrementQuantity = (orderId, item) => {
    if (item.quantity > 1) {
      const newQuantity = item.quantity - 1;
      updateOrderItemQuantity(orderId, item.menu_item_id, newQuantity);
    }
  };

  const handleGoBack = () => {
    setAddMenuItemOrder(null);
    setSearchTerm('');
  };

  const handleDeleteOrderItem = (orderId, itemId) => {
    setOrders(prevOrders => {
      const updatedOrders = prevOrders.map(order => {
        if (order.order_id === orderId) {
          const updatedItems = order.items.filter(item => item.menu_item_id !== itemId);
          return { ...order, items: updatedItems, total_amount: calculateOrderTotal(updatedItems) };
        }
        return order;
      });

      setModifiedOrders(prev => ({
        ...prev,
        [orderId]: {
          ...prev[orderId],
          items: updatedOrders.find(order => order.order_id === orderId).items
        }
      }));

      return updatedOrders;
    });
  };

  const getMenuItems = async (order) => {
    setAddMenuItemOrder(order);
    try {
      const response = await axios.get(`${config.API_BASE_URL}/menu/get-menu-items/${restaurantId}`);
      const allMenuItems = response.data;
      const existingItemIds = order.items.map(item => item.menu_item_id);
      const filteredMenuItems = allMenuItems.filter(item => !existingItemIds.includes(item.menu_id));
      setMenuItems(filteredMenuItems);
    } catch (error) {
      console.error('Error fetching menu items:', error);
    }
  };

  const handleDeleteOrder = (orderId) => {
    setConfirmDeleteOrderId(orderId);
  };

  
  const addMenuItemToOrder = (menuItem) => {
    const order = addMenuItemOrder;
    const updatedItems = [...order.items, { menu_item_id: menuItem.menu_id, quantity: 1, price: menuItem.price, product_name: menuItem.item_name }];
    const newTotal = calculateOrderTotal(updatedItems);

    setOrders(prevOrders => prevOrders.map(o => 
      o.order_id === order.order_id ? { ...o, items: updatedItems, total_amount: newTotal } : o
    ));

    setModifiedOrders(prev => ({
      ...prev,
      [order.order_id]: {
        ...order,
        items: updatedItems,
        total_amount: newTotal
      }
    }));
    
    setAddMenuItemOrder(null); 
  };

  const confirmDeleteOrder = async (orderId) => {
    try {
      const response = await axios.delete(`${config.API_BASE_URL}/owner/restaurant/${restaurantId}/delete-order/${orderId}`);
      if (response.status === 200) {
        sessionStorage.setItem('orderDeleted', 'true');
        fetchOrders();
      }
    } catch (error) {
      console.error('Error deleting order:', error);
    } finally {
      setConfirmDeleteOrderId(null);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="order-management">
      <h3>Order Management</h3>
      {loading ? (
        <p>Loading orders...</p>
      ) : error ? (
        <div className="error">{error}</div>
      ) : orders.length === 0 ? (
        <p className="no-orders-message">No Orders Present</p>
      ) : (
        <ul>
          {orders.map((order) => (
            <li key={order.order_id} className="order-box">
              <div className="order-header">
                <div className="customer-info">
                  <p className="customer-name">{order.customer_name}</p>
                  <p className="customer-phone">{order.phone_number}</p>
                </div>
                <p className="table-number">Table: {order.table_number || 'Pick Up'}</p>
                <p className="order-total">${order.total_amount.toFixed(2)}</p>
                <p className="order-status">Status: {order.status}</p>
                <p className="order-id">Order #: {order.order_id}</p>
              </div>

              <ul className="order-items">
                {order.items.map(item => (
                  <li key={item.menu_item_id} className="menu-item">
                    <span className="item-name">{item.product_name}</span>
                    <span className="item-quantity">
                      <button className="quantity-btn" onClick={() => handleDecrementQuantity(order.order_id, item)}>-</button>
                      {item.quantity}
                      <button className="quantity-btn" onClick={() => handleIncrementQuantity(order.order_id, item)}>+</button>
                    </span>
                    <span className="item-price">${item.price.toFixed(2)}</span>
                    <button onClick={() => handleDeleteOrderItem(order.order_id, item.menu_item_id)} className="delete-item-btn">Delete</button>
                  </li>
                ))}
              </ul>

              {addMenuItemOrder && addMenuItemOrder.order_id === order.order_id ? (
                <div className="add-menu-items">
                  <input
                    type="text"
                    placeholder="Search menu items..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="search-bar"
                  />
                  <button className="go-back-btn" onClick={handleGoBack}>Back</button>
                  {menuItems
                    .filter(item => item.item_name.toLowerCase().includes(searchTerm.toLowerCase()))
                    .map(item => (
                      <div key={item.menu_id} className="menu-item">
                        <span className="item-name">{item.item_name}</span>
                        <span className="item-price">${item.price.toFixed(2)}</span>
                        <button className="add-menu-item-btn" onClick={() => addMenuItemToOrder(item)}>Add</button>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="order-actions-container">
                  {order.status === 'Pending' ? (
                    <button className="approve-order-btn" onClick={() => handleApproveOrder(order.order_id)}>
                      {modifiedOrders[order.order_id] ? 'Update Order' : 'Approve Order'}
                    </button>
                  ) : null}
                  <button onClick={() => getMenuItems(order)} className="add-items-btn">Add Items</button>
                  {confirmDeleteOrderId === order.order_id ? (
                    <>
                      <button className="confirm-delete-btn" onClick={() => confirmDeleteOrder(order.order_id)}>
                        Confirm Delete
                      </button>
                      <button className="cancel-delete-btn" onClick={() => setConfirmDeleteOrderId(null)}>
                        Cancel
                      </button>
                    </>
                  ) : (
                    <button className="delete-order-btn" onClick={() => handleDeleteOrder(order.order_id)}>
                      Delete Order
                    </button>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default OrderManagement;

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import './Login.css';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    is_verified: false
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setShowForgotPassword(true);
  };
  
  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${config.API_BASE_URL}/auth/forgot_password`, { email: forgotPasswordEmail });
      setErrorMessage('Password reset link sent to your email.');
      setShowForgotPassword(false);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setErrorMessage('Please check your email, if this account exist, you will receive an password reset email');
        }else{
          setErrorMessage('An error occurred. Please try again.');
        }
      }
    }
  };
  

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.post(`${config.API_BASE_URL}/auth/login`, formData);
    sessionStorage.setItem('user_id', response.data.user_id);
    if (response.data && response.data.resturant){
      sessionStorage.setItem('restaurant_id', response.data.resturant.restaurant_id);
    }
    if (!response.data.subscription_id) {
      navigate('/select-subscription');
    } else {
      navigate('/dashboard');
    }
  } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setErrorMessage('User not found');
        } else if (error.response.status === 401) {
          setErrorMessage('Invalid password/username');
        } else if (error.response.status === 409) {
          setErrorMessage('Verify your Email');
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }      
    }
  };

  return (
    <div className="login">
      <h2>Login</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {!showForgotPassword && (
        <form onSubmit={handleSubmit}>
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          <label>Password:</label>
          <input type="password" name="password" value={formData.password} onChange={handleChange} required />
          <button type="submit">Login</button>
          <p>
            <a href="#" onClick={() => setShowForgotPassword(true)}>Forgot Password?</a>
          </p>
        </form>
      )}
      {showForgotPassword && (
        <div className="forgot-password-modal">
          <h3>Forgot Password</h3>
          <form onSubmit={handleForgotPasswordSubmit}>
            <label>Email:</label>
            <input
              type="email"
              value={forgotPasswordEmail}
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
              required
            />
            <button type="submit">Reset Password</button>
            <button type="button" onClick={() => setShowForgotPassword(false)}>Cancel</button>
          </form>
        </div>
      )}

    </div>
  );
};

export default Login;


import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import './CustomerOrderStatus.css';

const CustomerOrderStatus = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const restaurant_id = location.state?.restaurant_id || new URLSearchParams(location.search).get('restaurant_id') || localStorage.getItem('restaurant_id');
  const [orderDetails, setOrderDetails] = useState({});
  const [statuses, setStatuses] = useState([
    { label: 'Order Received', value: 'Pending', checked: false },
    { label: 'Order Confirmed', value: 'Approved', checked: false },
    { label: 'Being Prepared', value: 'Approved', checked: false },
    { label: 'Ready', value: 'Ready', checked: false },
    { label: 'Complete', value: 'Completed', checked: false },
  ]);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [detailedOrderInfo, setDetailedOrderInfo] = useState(null);
  const [restaurantDetail, setRestaurantDetail] = useState('');
  const [orderCancelled, setOrderCancelled] = useState(false);
  
  const restaurantIdRef = useRef(restaurant_id);

  useEffect(() => {
    fetchOrderStatus();
    const fetchRestaurantDetails = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/customer/display-menu/${restaurant_id}`);
        setRestaurantDetail(response.data.resturant);
      } catch (error) {
        console.log("Error fetching restaurant details:", error);
      }
    };
    
    if (restaurant_id) {
      fetchRestaurantDetails();
    }
    return () => {
      navigate(`/menu-display?id=${restaurantIdRef.current}`, { replace: true });
    };
  }, [restaurant_id, navigate]);

  const fetchOrderStatus = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/customer/order-summary/${restaurant_id}/${location.state ? location.state.orderId : localStorage.getItem('orderId')}`);
      if (response.status === 200) {
        setOrderDetails(response.data);
        const fetchedStatus = response.data.status;
        if (fetchedStatus === 'Cancelled') {
          setOrderCancelled(true);
        } else {
          const statusIndex = statuses.findIndex(status => status.value === fetchedStatus);
          const updatedStatuses = statuses.map((status, index) => ({
            ...status,
            checked: index <= statusIndex,
          }));
          setStatuses(updatedStatuses);
        }
      }
    } catch (error) {
      console.error('Error fetching order status:', error);
    }
  };

  const fetchOrderDetails = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/customer/order-summary/${restaurant_id}/${location.state ? location.state.orderId : localStorage.getItem('orderId')}`);
      setDetailedOrderInfo(response.data);
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
};

  const handleShowOrderDetails = () => {
    if (!detailedOrderInfo) {
      fetchOrderDetails();
    }
    setShowOrderDetails(!showOrderDetails);
  };

  return (
    <div className="order-status-container">
      <div className="restaurant-info">
        <h1 className="restaurant-name">{restaurantDetail.name}</h1>
        <p className="restaurant-details">
          {restaurantDetail.address}, {restaurantDetail.city}, {restaurantDetail.state} • {restaurantDetail.phone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
        </p>
      </div>
      {orderCancelled ? (
        <div className="order-cancelled-message">
          <h3>Your Order is Cancelled</h3>
        </div>
      ) : (
        <>
          <div className="status-list">
            {statuses.map((status, index) => (
              <label key={index} className={`status-item ${status.checked ? 'checked' : ''}`}>
                <input type="checkbox" checked={status.checked} readOnly />
                {status.label}
              </label>
            ))}
          </div>
          <div className="total">
            <span>Total</span>
            <span className="total-amount">${orderDetails.total_amount?.toFixed(2) || '0.00'}</span>
          </div>
          <button onClick={handleShowOrderDetails} className="show-details-btn">
            Order#{detailedOrderInfo?.order_id || orderDetails?.order_id} {showOrderDetails ? '(Hide Details)' : '(Show Details)'}
          </button>
          {showOrderDetails && detailedOrderInfo && (
            <div className="order-details">
              <h3>Order Details</h3>
              <ul>
                {detailedOrderInfo.items.map((item, index) => (
                  <li key={index} className="order-item">
                    <span className="item-name">{item.product_name}</span>
                    <span className="item-quantity">Qty: {item.quantity}</span>
                    <span className="item-price">Price: ${item.price.toFixed(2)}</span>
                  </li>
                ))}
              </ul>
              <p className="order-total">Total: ${detailedOrderInfo.total_amount.toFixed(2)}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomerOrderStatus;
import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import NotificationCart from "./NotificationCart";
import "./EditRestaurant.css";

const EditRestaurant = () => {
  const [restaurant, setRestaurant] = useState({});
  const [countries, setCountries] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [isNotification, setIsNotification] = useState(false);
  const [states, setStates] = useState([]);
  const [formData, setFormData] = useState({
    restaurant_name: "",
    address: "",
    email: "",
    phone: "",
    restaurant_type: "",
    number_of_tables: 0,
    opening_hours: {
      monday: {
        open: "",
        close: "",
        pickup: "",
      },
      tuesday: {
        open: "",
        close: "",
        pickup: "",
      },
      wednesday: {
        open: "",
        close: "",
        pickup: "",
      },
      thursday: {
        open: "",
        close: "",
        pickup: "",
      },
      friday: {
        open: "",
        close: "",
        pickup: "",
      },
      saturday: {
        open: "",
        close: "",
        pickup: "",
      },
      sunday: {
        open: "",
        close: "",
        pickup: "",
      },
    },
    city: "",
    state: "",
    country: "",
    zip_code: "",
    user_id: "",
  });

  useEffect(() => {
    fetchRestaurant();
    fetchCountries();
    console.log("formData :------", formData);
  }, []);

  const fetchRestaurant = async () => {
    const response = await axios.get(
      `${
        config.API_BASE_URL
      }/restaurant/get-restaurant/${sessionStorage.getItem("restaurant_id")}`
    );
    const data = response.data.data;
    console.log("data", response.data);

    setRestaurant(data);
    setFormData({
      restaurant_name: data.restaurant_name,
      address: data.address,
      email: data.email,
      phone: data.phone,
      restaurant_type: data.restaurant_type,
      number_of_tables: Number(data.number_of_tables),
      opening_hours: {
        monday: {
          open: data.opening_hours.monday.open,
          close: data.opening_hours.monday.close,
          pickup: data.opening_hours.monday.pickup,
        },
        tuesday: {
          open: data.opening_hours.tuesday.open,
          close: data.opening_hours.tuesday.close,
          pickup: data.opening_hours.tuesday.pickup,
        },
        wednesday: {
          open: data.opening_hours.wednesday.open,
          close: data.opening_hours.wednesday.close,
          pickup: data.opening_hours.wednesday.pickup,
        },
        thursday: {
          open: data.opening_hours.thursday.open,
          close: data.opening_hours.thursday.close,
          pickup: data.opening_hours.thursday.pickup,
        },
        friday: {
          open: data.opening_hours.friday.open,
          close: data.opening_hours.friday.close,
          pickup: data.opening_hours.friday.pickup,
        },
        saturday: {
          open: data.opening_hours.saturday.open,
          close: data.opening_hours.saturday.close,
          pickup: data.opening_hours.saturday.pickup,
        },
        sunday: {
          open: data.opening_hours.sunday.open,
          close: data.opening_hours.sunday.close,
          pickup: data.opening_hours.sunday.pickup,
        },
      },
      city: data.city,
      state: data.state,
      country: data.country,
      zip_code: data.zip_code,
      user_id: sessionStorage.getItem("user_id"),
    });
    fetchStates(data.country);
  };
  const fetchCountries = () => {
    const availableCountries = [
      "USA",
      "Canada",
      "UK",
      "Australia",
      "Germany",
      "France",
      "Japan",
      "India",
      "Brazil",
      "Mexico",
    ];
    setCountries(availableCountries);
    if (formData.country) {
      fetchStates(formData.country);
    }
  };

  const fetchStates = (country) => {
    const statesByCountry = {
      USA: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      Canada: [
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Newfoundland and Labrador",
        "Northwest Territories",
        "Nova Scotia",
        "Nunavut",
        "Ontario",
        "Prince Edward Island",
        "Quebec",
        "Saskatchewan",
        "Yukon",
      ],
      UK: ["England", "Scotland", "Wales", "Northern Ireland"],
      Australia: [
        "New South Wales",
        "Victoria",
        "Queensland",
        "Western Australia",
        "South Australia",
        "Tasmania",
        "Australian Capital Territory",
        "Northern Territory",
      ],
      Germany: [
        "Baden-Württemberg",
        "Bavaria",
        "Berlin",
        "Brandenburg",
        "Bremen",
        "Hamburg",
        "Hesse",
        "Lower Saxony",
        "Mecklenburg-Vorpommern",
        "North Rhine-Westphalia",
        "Rhineland-Palatinate",
        "Saarland",
        "Saxony",
        "Saxony-Anhalt",
        "Schleswig-Holstein",
        "Thuringia",
      ],
      France: [
        "Auvergne-Rhône-Alpes",
        "Bourgogne-Franche-Comté",
        "Brittany",
        "Centre-Val de Loire",
        "Corsica",
        "Grand Est",
        "Hauts-de-France",
        "Île-de-France",
        "Normandy",
        "Nouvelle-Aquitaine",
        "Occitanie",
        "Pays de la Loire",
        "Provence-Alpes-Côte d'Azur",
      ],
      Japan: [
        "Hokkaido",
        "Aomori",
        "Iwate",
        "Miyagi",
        "Akita",
        "Yamagata",
        "Fukushima",
        "Ibaraki",
        "Tochigi",
        "Gunma",
        "Saitama",
        "Chiba",
        "Tokyo",
        "Kanagawa",
        "Niigata",
        "Toyama",
        "Ishikawa",
        "Fukui",
        "Yamanashi",
        "Nagano",
        "Gifu",
        "Shizuoka",
        "Aichi",
        "Mie",
        "Shiga",
        "Kyoto",
        "Osaka",
        "Hyogo",
        "Nara",
        "Wakayama",
        "Tottori",
        "Shimane",
        "Okayama",
        "Hiroshima",
        "Yamaguchi",
        "Tokushima",
        "Kagawa",
        "Ehime",
        "Kochi",
        "Fukuoka",
        "Saga",
        "Nagasaki",
        "Kumamoto",
        "Oita",
        "Miyazaki",
        "Kagoshima",
        "Okinawa",
      ],
      India: [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
      ],
      Brazil: [
        "Acre",
        "Alagoas",
        "Amapá",
        "Amazonas",
        "Bahia",
        "Ceará",
        "Distrito Federal",
        "Espírito Santo",
        "Goiás",
        "Maranhão",
        "Mato Grosso",
        "Mato Grosso do Sul",
        "Minas Gerais",
        "Pará",
        "Paraíba",
        "Paraná",
        "Pernambuco",
        "Piauí",
        "Rio de Janeiro",
        "Rio Grande do Norte",
        "Rio Grande do Sul",
        "Rondônia",
        "Roraima",
        "Santa Catarina",
        "São Paulo",
        "Sergipe",
        "Tocantins",
      ],
      Mexico: [
        "Aguascalientes",
        "Baja California",
        "Baja California Sur",
        "Campeche",
        "Chiapas",
        "Chihuahua",
        "Coahuila",
        "Colima",
        "Durango",
        "Guanajuato",
        "Guerrero",
        "Hidalgo",
        "Jalisco",
        "México",
        "Mexico City",
        "Michoacán",
        "Morelos",
        "Nayarit",
        "Nuevo León",
        "Oaxaca",
        "Puebla",
        "Querétaro",
        "Quintana Roo",
        "San Luis Potosí",
        "Sinaloa",
        "Sonora",
        "Tabasco",
        "Tamaulipas",
        "Tlaxcala",
        "Veracruz",
        "Yucatán",
        "Zacatecas",
      ],
    };
    setStates(statesByCountry[country] || []);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith("opening_hours.")) {
      const [_, day, field] = name.split(".");
      setFormData((prevData) => ({
        ...prevData,
        opening_hours: {
          ...prevData.opening_hours,
          [day]: {
            ...prevData.opening_hours[day],
            [field]: type === "checkbox" ? checked : value,
          },
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }

    if (name === "country") {
      fetchStates(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSubmit = {
      ...formData,
      opening_hours: formData.opening_hours,
    };

    try {
      const response = await axios.put(
        `${config.API_BASE_URL}/restaurant/update-restaurant/${restaurant.restaurant_id}`,
        dataToSubmit
      );
      if (response.status === 200) {
        setNotificationMessage(response.data.message);
        setIsNotification(true);
        setNotificationStatus("sucess");
        setTimeout(() => {
          setIsNotification(false);
        }, 9000);
      } else {
        throw new Error("Error updating restaurant");
      }
    } catch (error) {
      console.error("Error updating restaurant:", error);
      setNotificationMessage("Error updating restaurant");
      setIsNotification(true);
      setNotificationStatus("error");
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
    }
  };

  return (
    <div className="edit-restaurant">
      <h2>Edit Restaurant</h2>
      {isNotification && (
        <NotificationCart
          message={notificationMessage}
          status={notificationStatus}
        />
      )}
      <form onSubmit={handleSubmit}>
        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor="restaurant_name">Restaurant Name:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="restaurant_name"
                  name="restaurant_name"
                  value={formData.restaurant_name}
                  onChange={handleChange}
                  required
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="email">Email:</label>
              </td>
              <td>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="phone">Phone:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={restaurant.phone}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="address">Address:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="city">City:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="state">State:</label>
              </td>
              <td>
                <select
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a state</option>
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="zip_code">Zip Code:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="zip_code"
                  name="zip_code"
                  value={formData.zip_code}
                  onChange={handleChange}
                  required
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="country">Country:</label>
              </td>
              <td>
                <select
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a country</option>
                  {countries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="restaurant_type">Restaurant Type:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="restaurant_type"
                  name="restaurant_type"
                  value={formData.restaurant_type}
                  onChange={handleChange}
                  required
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="number_of_tables">Number of Tables:</label>
              </td>
              <td>
                <input
                  type="number"
                  id="number_of_tables"
                  name="number_of_tables"
                  value={formData.number_of_tables}
                  onChange={handleChange}
                  required
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>Opening Hours:</label>
              </td>
              <td>
                <div className="opening-hours-container">
                  <div className="days-row">
                    {Object.keys(formData.opening_hours).map((day) => (
                      <div key={day} className="day-column">
                        <div className="day-name">
                          {day.charAt(0).toUpperCase() + day.slice(1)}
                        </div>
                        <div className="time-           inputs">
                          <input
                            type="time"
                            name={`opening_hours.${day}.open`}
                            value={formData.opening_hours[day].open}
                            onChange={handleChange}
                          />
                          <span>to</span>
                          <input
                            type="time"
                            name={`opening_hours.${day}.close`}
                            value={formData.opening_hours[day].close}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="pickup-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name={`opening_hours.${day}.pickup`}
                              checked={formData.opening_hours[day].pickup}
                              onChange={handleChange}
                            />
                            Pickup
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <button type="submit">Update Restaurant</button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default EditRestaurant;

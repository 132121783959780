import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import config from "../config";
import "./KitchenManagement.css";

function KitchenManagement() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const presentTime = new Date();
  const [currentTime, setCurrentTime] = useState(
    new Date(
      Date.UTC(
        presentTime.getUTCFullYear(),
        presentTime.getUTCMonth(),
        presentTime.getUTCDate(),
        presentTime.getUTCHours(),
        presentTime.getUTCMinutes(),
        presentTime.getUTCSeconds()
      )
    )
  ); // State for current time
  const restaurantId = sessionStorage.getItem("restaurant_id");

  // Fetch orders when the component mounts
  const fetchKitchenOrders = useCallback(async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/kitchen/restaurant/${restaurantId}/orders`
      );
      setOrders(response.data);
    } catch (error) {
      setError("Failed to load orders. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [restaurantId]);

  useEffect(() => {
    fetchKitchenOrders();
  }, [fetchKitchenOrders]);
  
  // Update current time in GMT every second
  useEffect(() => {
    const timerInterval = setInterval(() => {
      const now = new Date();
      const gmtTime = new Date(
        Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds()
        )
      ).getTime();

      setCurrentTime(gmtTime); // Update current time state in GMT
    }, 1000);

    return () => clearInterval(timerInterval); // Cleanup on unmount
  }, []);

  // Helper function to format time difference
  const formatTimeDifference = (updatedAt) => {
    const orderTime = new Date(updatedAt).getTime(); // Assumes updatedAt is in GMT/UTC

    const diffInSeconds = Math.floor((currentTime - orderTime) / 1000);

    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleUpdateOrderStatus = async (orderId, status) => {
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/kitchen/restaurant/${restaurantId}/update-order-status/${orderId}`,
        { status }
      );
      if (response.status === 200) {
        setOrders(
          orders.map((order) =>
            order.order_id === orderId ? { ...order, status } : order
          )
        );
        fetchKitchenOrders();
      }
    } catch (error) {
      alert(
        `Failed to update order status to ${status}. Please try again later.`
      );
    }
  };

  return (
    <div className="kitchen-management">
      <h2>Kitchen Management</h2>
      {loading ? (
        <p className="loading-message">Loading orders...</p>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : orders.length === 0 ? ( // Check if orders array is empty
        <p className="no-orders-message">No Orders Present</p>
      ) : (
        <div className="order-list">
          {orders.map((order) => (
            <div key={order.order_id} className="order-item">
              <div className="order-header">
                Order #{order.order_id} -{" "}
                {order.table_number
                  ? `Table Number: ${order.table_number}`
                  : `PickUp Order`}
              </div>
              <div className="order-details">
                <div className="order-info">
                  <p>
                    <strong>Customer:</strong> {order.customer_name}
                  </p>
                  <p>
                    <strong>Total:</strong> ${order.total_amount.toFixed(2)}
                  </p>
                  <p>
                    <strong>Status:</strong>{" "}
                    <span
                      className={`order-status ${order.status
                        .toLowerCase()
                        .replace(" ", "-")}`}
                    >
                      {order.status}
                    </span>
                  </p>
                  <p>
                    <strong>Time Elapsed:</strong>{" "}
                    {formatTimeDifference(order.updated_at)}
                  </p>
                </div>
                <ul className="order-items">
                  {order.items.map((item) => (
                    <li key={item.menu_item_id} className="order-item-detail">
                      <span>
                        {item.product_name} (x{item.quantity})
                      </span>
                      <span>${item.price.toFixed(2)}</span>
                    </li>
                  ))}
                </ul>
                <div className="order-actions">
                  <button
                    onClick={() =>
                      handleUpdateOrderStatus(order.order_id, "Ready")
                    }
                  >
                    Order Prepared
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default KitchenManagement;

import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import NotificationCart from "./NotificationCart";
import "./CustomerLogin.css";

const CustomerLogin = ({ onLoginSuccess, onClose }) => {
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [formMode, setFormMode] = useState("login");
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    customer_name: "",
    phone_number: "",
  });
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [isNotification, setIsNotification] = useState(false);

  useEffect(() => {
    const storedUserData = localStorage.getItem("customerData");
    if (storedUserData && storedUserData !== "undefined") {
      const parsedData = JSON.parse(storedUserData);
      setUserData(parsedData);
      onLoginSuccess(parsedData);
    }
  }, [onLoginSuccess]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.API_BASE_URL}/customer/login`, userData);
      setUserData(response.data);
      localStorage.setItem("customerData", JSON.stringify(response.data));
      setNotificationMessage("Login successful");
      setNotificationStatus("success");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
        onLoginSuccess(response.data);
      }, 9000);
    } catch (error) {
      setNotificationMessage("Invalid email or password");
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
    }
  };

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      setNotificationMessage("Please accept the Terms and Conditions");
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
      return;
    }
    if (userData.password !== passwordConfirmation) {
      setNotificationMessage("Passwords do not match");
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
      return;
    }
    try {
      const response = await axios.post(`${config.API_BASE_URL}/customer/create`, userData);
      setUserData(response.data);
      localStorage.setItem("customerData", JSON.stringify(response.data));
      setNotificationMessage("Account created successfully");
      setNotificationStatus("success");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
        onLoginSuccess(response.data);
      }, 9000);
    } catch (error) {
      setNotificationMessage("Error creating account. Please try again.");
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.API_BASE_URL}/customer/forgot-password`, {
        email: resetEmail,
      });
      setNotificationMessage(response.data.message || "Password reset link sent to your email");
      setNotificationStatus("success");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
        setFormMode("login");
      }, 9000);
    } catch (error) {
      setNotificationMessage("Error resetting password. Please try again.");
      setNotificationStatus("error");
      setIsNotification(true);
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
    }
  };

  return (
    <div className="customer-login-container">
      <button className="back-btn" onClick={handleClose}>×</button>
      <h1>Customer Login</h1>
      {isNotification && (
        <NotificationCart
          message={notificationMessage}
          status={notificationStatus}
        />
      )}

      {formMode === "forgotPassword" && (
        <div>
          <h2>Forgot Password</h2>
          <form onSubmit={handleForgotPassword}>
            <div className="form-group">
              <label htmlFor="resetEmail">Email:</label>
              <input
                type="email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit">Send Reset Link</button>
            <button type="button" onClick={() => setFormMode("login")}>
              Back to Login
            </button>
          </form>
        </div>
      )}

      {formMode === "login" && (
        <div className="user-info">
          <h2>Log In</h2>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                value={userData.email}
                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                type="password"
                value={userData.password}
                onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                required
              />
            </div>
            <button type="submit" className="login-btn">Log In</button>
            <button type="button" className="signup-btn" onClick={() => setFormMode("signup")}>
              Create Account
            </button>
            <a href="#" onClick={() => setFormMode("forgotPassword")}>
              Forgot Password?
            </a>
          </form>
        </div>
      )}

      {formMode === "signup" && (
        <div>
          <h2>Create Account</h2>
          <form onSubmit={handleSignup}>
            <div className="form-group">
              <label>Name:</label>
              <input
                type="text"
                value={userData.customer_name}
                onChange={(e) => setUserData({ ...userData, customer_name: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                value={userData.email}
                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label>Phone Number:</label>
              <input
                type="tel"
                value={userData.phone_number}
                onChange={(e) => setUserData({ ...userData, phone_number: e.target.value })}
                pattern="[0-9]{10}"
                maxLength="10"
                required
              />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                type="password"
                value={userData.password}
                onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label>Confirm Password:</label>
              <input
                type="password"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                required
              />
            </div>
            <div className="terms-container">
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                required
              />
              <label>
                I agree to the{" "}
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Terms and Conditions
                </a>
              </label>
            </div>
            <button type="submit">Sign Up</button>
            <button type="button" onClick={() => setFormMode("login")}>
              Already have an account? Log In
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default CustomerLogin;
